<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES CLIENTS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.verified="{ item }">
            <v-chip :color="item.verified ==1?'green':'red'" dark>
                {{ item.verified ==1?'Confirmer':'Non confirmer' }}
            </v-chip>
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip :color="item.status ==1?'green':'red'" dark>
                {{ item.status ==1?'Valider':'Non valider' }}
            </v-chip>

        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="teal" large class="mr-2" @click="getitemroute(item)">
                mdi-information-outline
            </v-icon>

        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

    
<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "KYC-CLIENT",

    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'KYC-CLIENT'
    },
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '50%'

            },
            {
                text: "status",
                value: "status",
                divider: true,
                width: '20px'
            },
            {
                text: "Photo identité",
                align: "start",
                sortable: false,
                value: "photo",
                divider: true,
                width: '100%'
            },
            {
                text: "CODE CLIENT",
                align: "start",
                sortable: false,
                value: "key",
                divider: true,
                width: '80px'
            },
            {
                text: "NOM",
                align: "start",
                sortable: false,
                value: "personnel.noms",
                divider: true,
                width: '150px'
            },
            {
                text: "PRENOM",
                align: "start",
                sortable: false,
                value: "personnel.prenoms",
                divider: true,
                width: '150px'
            },

            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "email",
                divider: true,
                width: '150px'
            },
            {
                text: "Reference doc",
                align: "start",
                sortable: false,
                value: "solde",
                divider: true,
                width: '150px'
            },
            {
                text: "Document",
                align: "start",
                sortable: false,
                value: "solde",
                divider: true,
                width: '100px'
            },
            {
                text: "Telephone",
                align: "start",
                sortable: false,
                value: "parrain.key",
                divider: true,
                width: '100px'
            },
            {
                text: "Whatsapp",
                align: "start",
                sortable: false,
                value: "parrain.key",
                divider: true,
                width: '100px'
            },

        ],

        list: [],
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],
        valeur: '',
        etat: false,
        selecttype_recherche: 'CODE CLIENT',
        libelle_recherche: '',

    }),

    created() {
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('colonne', this.selecttype_recherche == 'CODE CLIENT' ? 'key' : 'email');
            fd.append('valeur', this.libelle_recherche);
            const data = await api.createUpdatedata('backoffice/all-clients-kyc', fd);
            this.list = data;

        },

        getitemroute(item) {
            const encryptedText_id = this.$CryptoJS.AES.encrypt('sddfef' + item.id, "Safajis2023").toString();
            const encryptedText_key = this.$CryptoJS.AES.encrypt('' + item.key, "Safajis2023").toString();
            console.log(encryptedText_id)
            console.log(encryptedText_key)
            this.$router.push({
                name: 'client-kyc-profil',
                params: {
                    id: encryptedText_id,
                    key: encryptedText_key
                }
            })
        },

        /* showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },*/

        fetchData() {
            this.readAll();
        },

    },
};
</script>
